console.log('asd')

const Services = {
    data() {
        return {
            services: [
                // {
                //     id: '1',
                //     name: 'Услуга 1',
                //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam corrupti explicabo repudiandae voluptatibus sed neque.',
                //     image: 'Изображение услуги 1'
                // },
                {
                    id: '1',
                    name: 'Недвижимость',
                    description: 'Эффективное продвижение недвижимости и Жилых Комплексов с использованием рекламы в сетях Google; Facebook и Instagram',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '2',
                    name: 'Строительство',
                    description: 'Реклама и продвижение строительных услуг в интернете',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '3',
                    name: 'Ремонт',
                    description: 'Поможем Вам создать успешную рекламную компанию в интернете на строительные услуги, ремонт квартир, домов и офисов',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '4',
                    name: 'Туризм',
                    description: 'Комплексный подход к рекламе Турагентств и Туроператоров',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '5',
                    name: 'Авто',
                    description: 'Продвижение автомобильной тематики в Google , Facebook и Instagram с помощью видео и банерной рекламы',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '6',
                    name: 'Сельхоз техника',
                    description: 'Мы знаем как продавать сельскохозяйственную технику через интернет',
                    image: 'Изображение услуги 1'
                },
                {
                    id: '7',
                    name: 'Производство',
                    description: 'Мы знаем как ярко выделить Ваше производство среди конкурентов и успешно масштабировать бизнес на рынке Украины и СНГ',
                    image: 'Изображение услуги 1'
                }
            ],
        }
    }
}

Vue.createApp(Services).mount('#v-services')